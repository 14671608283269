import { createElement, PropsWithChildren } from 'react';

import styles from './Flexbox.module.scss';

/**
 * Default component props
 */
interface FlexboxProps extends PropsWithChildren {
    align?: 'start' | 'center';
    as: string;
    classes?: string;
    display?: 'flex' | 'inline-flex';
    justify?: 'start' | 'center';
    position?: 'absolute' | 'relative';
}

/**
 * Props prevent both row and column from being passed at the same time
 */
type ColumnProps = FlexboxProps & { column: true; row?: never };
type RowProps = FlexboxProps & { row?: true; column?: never };

type Props = RowProps | ColumnProps;

/**
 * Flexbox component usable as a generic "container"
 * allowing for easy alignment and justification of children,
 * and reduce repeated flexbox styling.
 */

type Flexbox = typeof Flexbox;
export const Flexbox = ({
    align,
    as = 'div',
    classes = '',
    display = 'flex',
    justify,
    position = 'relative',
    children,
    ...props
}: Props) => {
    const alignClass = styles[`align-${align}`];
    const displayClass = styles[display];
    const directionClass = styles[props.column ? 'column' : 'row'];
    const justifyClass = styles[`justify-${justify}`];
    const positionClass = styles[position];

    const flexClasses = [
        styles.Flexbox,
        alignClass,
        directionClass,
        displayClass,
        classes,
        justifyClass,
        positionClass,
    ].join(' ');

    return createElement(as, { ...props, className: flexClasses }, children);
};
